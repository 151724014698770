body {
    font-family: 'Poppins', sans-serif;
    background-color: #F7F7F7!important;
}
a{
	text-decoration:none!important;
}
p{
	color: #626262;
}
img{
	max-width: 100%;
}
button:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color;
}
.loading {
    position: fixed;
    width: 100%;
    height: 100%;
    display: table;
    background: rgba(255,255,255,0.7);
    z-index: 9999999;
}
.loader {
    display: table-cell;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}
.loader img {
    width: 70px;
}
/* The custom-check */
.custom-check {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #828282;
}

/* Hide the browser's default checkbox */
.custom-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    background-color: transparent;
    border: 2px solid #D7D7D7;
    border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.custom-check:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-check input:checked ~ .checkmark {
  background-color: #000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-check .checkmark:after {
  left: 9px;
  top: 5px;
  width: 7px;
    height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/*The custom-radio */
.custom-radio {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #757575;
    font-size: 14px;
}

/* Hide the browser's default checkbox */
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Show the checkmark when checked */
.custom-radio input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .custom-radio .checkmark:after {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #000;
    position: static;
}

/* On mouse-over, add a grey background color */
.custom-radio:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .custom-radio input:checked ~ .checkmark {
    background-color: transparent;
    border-color: #000;
}
.custom-radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    background-color: transparent;
    border: 2px solid #ccc;
    border-radius: 50%;
    padding: 3px;
}
.dashboard-logo img {
    margin-right: 10px;
    max-height: 50px;
    max-width: 125px;
}
h3.page-name {
    float: left;
    margin-left: 20px;
    font-weight: 600;
    font-size: 25px;
    margin-top: 9px;
}
.small-logo {
    display: none;
}
.nav-sm .large-logo {
    display: none;
}
.nav-sm .small-logo {
    display: block;
}
.blue-txt {
    color: #E77469;
}
.dashboard-logo {
    font-weight: 600;
    font-size: 22px;
    padding: 0;
    float: left;
    width: 290px;
    margin-top: 5px;
    margin-bottom: 3rem;
}
.nav-sm .dashboard-logo {
    font-size: 0;
    width: 90px;
}
.nav-sm .navigation-bar h4 {
    font-size: 15px;
	padding-left: 10px;
}
/* .nav-sm .side-nav {
    width: 80px;
    padding-left: 10px;
} */
.nav-sm .navigation-bar > ul > li:hover > a {
    width: 260px;
}
.nav-sm .navigation-bar > ul > li:hover  a span.menu-title {
    display: inline-block;
}
.nav-sm .navigation-bar span.menu-title {
    display: none;
    width: 136px;
}
.nav-sm .navigation-bar > ul > li:hover  a span.open-menu {
    display: inline-block;
}
.nav-sm .navigation-bar ul ul.sub-menu {
    display: none;
}
.nav-sm .navigation-bar > ul > li:hover ul.sub-menu {
    display: block;
    position: absolute;
    background: #fff;
    top: 52px;
    margin-top: 0;
    width: 190px;
    left: 70px;
    padding-left: 0;
    box-shadow: 6px 8px 25px -4px rgb(0 0 0 / 10%);
    padding-top: 10px;
    padding-bottom: 10px;
}
.nav-sm .navigation-bar span.open-menu {
    display: none;
}
.nav-sm .navigation-bar h4 span {
    display: none;
}
.nav-sm .top-nav {
    padding-left: 20px;
}
.nav-sm .dashboard-inner {
    margin-left: 80px;
    width: calc(100% - 80px);
}
.dashboard-logo a {
    color: #d9ceb4;
}
.navigation-bar .dropdown-menu {
    position: static!important;
    transform: translate(0px, 0px)!important;
    border-width: 0;
    padding-left: 25px;
}
.side-nav {
    width: 300px;
    padding-left: 20px;
    position: fixed;
    left: 0!important;
    top: 8px;
    transition: width 0.3s ease;
    height: calc(100% - 83px);
    background: #fff;
    box-shadow: 0 15px 24px -8px rgb(0 0 0 / 20%);
    padding-top: 20px;
    z-index: 99999;
    overflow: auto;
    height: 100vh;
}

.navigation-bar h4 {
    font-size: 18px;
	margin-bottom:10px;
	padding-left: 20px;
}
.navigation-bar ul {
    padding: 0;
    margin-top: 20px;
}
.navigation-bar ul li {
    list-style: none;
	margin-bottom: 10px;
	position: relative;
}
.navigation-bar .dropdown-toggle.btn:after {
    display: none;
}
.navigation-bar ul li a, .navigation-bar .dropdown-toggle.btn {
    display: inline-block;
    width: 100%;
    padding: 15px 10px 15px 20px;
    font-size: 15px;
    color: #757575;
    border-right: 5px solid transparent;
    position: relative;    
    display: flex;
    align-items: center;
    text-decoration: none;
}
.navigation-bar ul li a i{
	margin-right:10px;
	font-size:17px;
}
.navigation-bar ul li a.active i, .navigation-bar > ul > li > a.active i {
    color: #fff;
}
.navigation-bar > ul > li > a.active, .navigation-bar > ul > li > a.active:hover, .navigation-bar > li > a.active, .navigation-bar > li > a.active:hover {
    background: #000!important;
    border-radius: 20px 0px 0px 20px;
    border-right: 5px solid #555555!important;
    color: #fff;
}
.navigation-bar > ul > li:has(a.active) > a {
    background: #000!important;
    border-radius: 20px 0px 0px 20px;
    border-right: 5px solid #555555!important;
    color: #fff;
}
.navigation-bar > ul > li:has(a.active) > a svg {
    color: #fff;
}
.navigation-bar > ul > li:has(a.active) .sub-menu {
    display: block;
}
.navigation-bar ul.sub-menu li a.active {
    border-width: 0;
    background: transparent;
}
.navigation-bar > ul > li:hover > a, .navigation-bar .dropdown-toggle.btn:hover {
    background: whitesmoke;
    border-radius: 20px 0px 0px 20px;
	border-right: 5px solid #e7e7e7;
}
.navigation-bar ul li.has-sub a {
	position: relative;
}
.navigation-bar .dropdown-menu a {
    background-color: transparent;
    padding: 5px 10px 5px 20px;
    font-size: 14px;
}
.navigation-bar .dropdown-menu a + a {
    margin-top: 10px;
}
.navigation-bar ul ul.sub-menu a {
    padding: 5px 10px 5px 20px;
    font-size: 14px;
}
.navigation-bar ul ul.sub-menu a:hover {
	color: #000;
}
.navigation-bar ul ul.sub-menu {
    padding-left: 24px;
}
.navigation-bar ul ul.sub-menu a.active {
    color: #000;
    font-weight: 500;
}
.sub-menu {
    display: none;
}
.sub-menu.show {
    display: block;
}
.open-menu {
    position: absolute;
    right: 10px;
    color: #757575;
    top: 21px;
}
.top-nav {
    position: fixed;
    top: 0;
    width: calc(100%);
    padding: 20px 40px 15px;
    background: #fff;
    z-index: 999;
}
.search-close {
    display: none;
}
.toggle-menu {
    float: left;
    padding: 0;
    background: transparent;
    border-width: 0;
}
.dashboard-title {
    float: left;
    margin-left: 40px;
    font-size: 22px;
    padding-top: 10px;
	font-weight: 600;
}
.nav-search {
    float: left;
    width: 350px;
    margin-left: 40px;
    position: relative;
    background: #F3F3F3;
    border-radius: 40px;
}
.search-open-btn {
    display: none;
}
.search-inner {
    background: #F3F3F3;
    border-radius: 20px;
    padding: 5px;
}
.nav-search input {
    background: transparent;
    border-width: 0;
    padding-left: 10px;
    width: calc(100% - 35px);
    outline: unset;
}
.search-form {
    display: flex;
}
.nav-search-btn {
    background: linear-gradient(122.47deg, #E77469 13.1%, #FEC072 80.56%);
    color: #fff;
    border-width: 0;
    width: 35px;
    height: 35px;
    border-radius: 50%;
}
.nav-search-btn:hover {
    background: linear-gradient(122.47deg,#FEC072 13.1%, #E77469 80.56%);
}
.profile-info img {
    vertical-align: middle;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}
.profile-name {
    float: left;
}
.profile-info {
    float: right;
    /* width: 200px;	 */
    margin-left: 70px;
	margin-top: -6px;
	position: relative;
}
.profile-tooltip {
    position: absolute;
    top: 71px;
    background: #626262;
    border-radius: 15px;
    width: 200px;
    box-shadow: 0px 3px 11px 3px rgb(211 214 228 / 25%);
    padding: 10px 0;
    display: none;
}
.profile-tooltip.show {
    display: block;
}
.profile-info-box {
    cursor: pointer;
    display: inline-flex;
  
}
.profile-tooltip a {
    display: inline-block;
    width: 100%;
    padding: 12px 15px;
    color: #fff;
    border-bottom: 1px solid rgba(255,255,255,0.16);
    font-weight: 300;
    font-size: 14px;
}
.profile-tooltip a i {
    margin-right: 5px;
}
.profile-tooltip a:hover {
    color: rgba(255,255,255,0.8);
}
.profile-tooltip a:last-child {
    border-bottom: 0px solid #f1f1f1;
}
.profile-tooltip a img {
    width: 20px;
	margin-right:10px;
    border-radius: 0;
}
.profile-name h5 {
    font-size: 15px;
    font-weight: 600;
    color: #000;
    margin: 0;
}
.profile-name span {
    color: #8F8F8F;
    font-size: 13px;
}
.profile-name {
    float: left;
    padding: 10px 10px 0;
}
.profile-icon {
    color: #D3D6E4;
    display: inline-block;
    margin-top: 13px;
    float: right;
    font-size: 20px;
}
.notification-icon {
    background: rgba(0, 0, 0, 0.1);
    width: 56px;
    height: 56px;
    border-radius: 20px;
    text-align: center;
    padding-top: 14px;
	position: relative;
	 cursor: pointer;
}
.message-notificaion {
    display: inline-block;
    float: right;
    margin-left: 20px;
    margin-top: -6px;
	    position: relative;
}
.notification-icon span {
    width: 20px;
    height: 20px;
    position: absolute;
    display: inline-block;
    background: #000;
    border-radius: 50%;
    border: 3px solid #FBFBFB;
    top: 0;
}
.dashboard-inner {
    width: calc(100% - 300px);
    margin: 90px 0 0 300px;
    padding: 30px;
}
.dashboard-top-btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}
.dashboard-inner .btn {
    border-radius: 10px;
}
.dashboard-top-btn .btn {
    padding: 10px 15px;
}
.dashboard-auto-wrapper {
    background: #fff;
    padding: 25px;
    border-radius: 10px;
}
.filter-box button.btn.btn-dark {
    border-radius: 50%;
    padding: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}
.wrapper-head {
    display: flex;
    justify-content: space-between;
}
button.btn.filter-btn img {
    width: 20px;
    margin-right: 5px;
}
button.btn.filter-btn {
    color: #757575;
    font-weight: 500;
}
.wrapper-head h3 {
    font-size: 22px;
    margin-bottom: 10px;
}
.follower-count-box {
    text-align: center;
}
span.follower-icon {
    display: flex;
    width: 80px;
    height: 80px;
    background: #E6E6E6;
    border-radius: 50%;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 15px;
}
.follower-count-box h4 {
    font-weight: 500;
    margin-bottom: 5px;
}
.follower-count-box p {
    color: rgba(0,0,0,0.6);
}
.dash-notification-box {
    padding: 10px 9px;
    display: flex;
}
.notification-user-img img {
    width: 30px;
    border-radius: 50%;
}
.notification-con {
    width: 100%;
}
.noti-user-name h5 {
    font-size: 16px;
    margin: 0;
    font-weight: 500;
}
.notification-con p {
    font-size: 15px;
    color: #979797;
    margin: 0;
}
.common-table thead tr th {
    background: #000;
    color: #fff;
    font-weight: 500;
    padding: 20px 15px 20px 20px;
}
.common-table thead tr th:last-child {
    border-top-right-radius: 10px;
}
.common-table thead tr th:first-child {
    border-top-left-radius: 10px;
}
.common-table .table td {
    background-color: #FAFAFA;
    border-top: 10px solid #fff;
    padding: 20px 15px 20px 20px;
    color: #757575;
}
.common-table .white-td-table td {
    background: #fff;
    border-top: 10px solid #FAFAFA;
}
.table-tab-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.table-tab-filter .dashboard-top-btn {
    margin: 0;
}
.table-tabs ul#pills-tab {
    border-bottom: 1px solid rgba(0,0,0,0.18);
}
.table-tabs ul#pills-tab .nav-link, #common-tabs-section .nav-link {
    background: transparent;
    padding: 10px 35px;
    border-width:0;
    border-bottom: 1px solid #dee2e6;
}
div#common-tabs-section {
    margin-top: -68px;
}
#common-tabs-section .nav.nav-tabs {
    border-bottom: 0;
}
.table-tabs .nav-pills .nav-link, #common-tabs-section .nav-link {
    color: rgba(0,0,0,0.4);
    font-weight: 500;
    font-size: 18px;
}
.table-tabs .nav-pills .nav-link.active, .table-tabs .nav-pills .show>.nav-link, #common-tabs-section .nav-link.active {
    color: #000;
    font-weight: 600;
    border-width: 0;
    border-bottom: 1px solid;
    border-radius: 0;
}
.common-table td:first-child {
    position: relative;
}
.common-table td:first-child:before {
    content: "";
    border-left: 4px solid #000;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
td.table-user img {
    width: 25px;
    margin-right: 5px;
}
td.table-user {
    font-weight: 500;
}
.comunication-form .form-control {
    background: rgba(230, 230, 230, 0.6);
    border-radius: 10px;
    height: 50px;
    border-width: 0;
}
.comunication-form .btn {
    height: 50px;
    width: 140px;
}
.comunication-form form {
    display: flex;
    column-gap: 20px;
}
.wrapper-head span {
    color: #929292;
    font-size: 14px;
}
.planner-revenue-graph img {
    width: 100%;
}
.planner-revenue-graph {
    margin-top: 15px;
}
.register-graph-head span.stats-name {
    font-size: 12px;
    margin-right: 15px;
}
.available-check {
    position: absolute;
    right: 5px;
    top: 15px;
}
.custom-check.available-check input:checked ~ .checkmark {
    background-color: transparent;
    border: 1px solid;
    border-radius: 5px;
	height: 20px;
    width: 20px;
}
.custom-check.available-check .checkmark:after {
    left: 6px;
    top: 2px;
    width: 6px;
    height: 11px;
    border: solid #E77469;
    border-width: 0 2px 2px 0;
}
.card-icon {
    width: 100px;
    height: 100px;
    text-align: center;
    border-radius: 50%;
    font-size: 45px;
    display: flex;
    align-items: center;
    margin: 0 auto;
}
.card-success .card-icon {
    color: #E77469;
    background: linear-gradient(0deg, rgba(231, 116, 105, 0.1), rgba(231, 116, 105, 0.1)), #FFFFFF;
}
.card-default .card-icon {
    background: rgba(217, 206, 180, 0.2);
	color:#D9CEB4;
}
.card-icon img {
    display: inline-block;
    margin: 0 auto;
}
.card-icon i {
	flex: 0 0 100%;
}
.dashboard-card {
    padding: 10px 30px 30px;
    display: inline-block;
    width: 100%;
    position: relative;
    text-align: center;
}
.card-text {
    margin-top: 15px;
}
.card-text label {
    color: #969BA0;
    margin-bottom: 10px;
}
.card-text h3 {
    font-weight: 700;
}
.card-progress {
    display: inline-block;
    width: calc(100% + 20px);
    height: 7px;
    background: #E7E7E7;
    position: absolute;
    left: -10px;
    bottom: -26px;
    border-radius: 10px;
}
.card-progress:before {
    content: "";
    background: #E77469;
    position: absolute;
    width: 200px;
    height: 7px;
    border-radius: 10px;
    left: 0;
}
.card-default .card-progress:before {
    content: "";
    background: #FEC072;
    position: absolute;
    width: 200px;
    height: 7px;
    border-radius: 10px;
}
.page-head h3 {
    font-weight: 600;
    font-size: 22px;
}
.page-head p {
    margin: 0;
}
.page-head {
    margin-bottom: 30px;
}
.report-table th {
    color: #E77469;
    font-size: 18px;
    font-weight: 500;
    padding: 18px 10px;
    border-bottom: 1px solid #efefef!important;
}
.report-table td {
    padding: 18px 10px;
    color: #757575;
    border-bottom: 1px solid #efefef!important;
}
.report-table td a {
	text-decoration: underline;
}
.report-table tr:last-child td {
    border-bottom: 0px solid #efefef!important;
}
.head-filters {
    display: inline-block;
    width: 100%;
    margin-top: -5px;
}
.filter-date-range {
    width: auto;
    float: right;
    margin: 0 20px;
    min-width: 300px;
}
#reportrange {
    background: transparent;
    cursor: pointer;
    padding: 8px 10px;
    border: 2px solid #E77469;
    width: 100%;
    border-radius: 7px;
    font-size: 15px;
}
#reportrange i.fas.fa-calendar-alt {
	color: #E77469;
}
#reportrange i.fas.fa-angle-down {
    float: right;
    font-size: 18px;
    margin-top: 2px;
    margin-left: 20px;
}
.next-previous-btns {
    float: right;
    background: #fff;
    border: 1px solid #D3D6E4;
    box-sizing: border-box;
    border-radius: 7px;
		padding: 4px;
}
.next-previous-btns a {
    display: inline-block;
    color: #D3D6E4;
    padding: 4px;
    width: 40px;
    text-align: center;
}
.next-previous-btns a:first-child {
	border-right: 1px solid #D3D6E4;
}
.notification-pop {
    position: absolute!important;
    background: #fff;
    width: 430px!important;
    right: 0;
    box-shadow: 0px 4px 4px 3px rgb(0 0 0 / 4%);
    border-radius: 20px;
    padding: 5px 25px 25px;
    top: 70px!important;
    /* display: none; */
    max-height: 70vh;
    overflow: auto;
    border: 1px solid #ddd;
    left: unset!important;
}
.notification-title {
    font-size: 19px;
    margin-top: 20px;
}
.notificaion-box {
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
    display: inline-block;
    width: 100%;
}
.notificaion-box img {
    width: 37px;
    border-radius: 10px;
    float: left;
}
.notification-con {
    /* width: calc(100% - 65px); */
    float: left;
    /* padding-left: 15px; */
}
.notification-con p {
    color: #000;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 0px;
    font-size: 14px;
}
.notifaction-date-time {
    color: #969BA0;
    font-size: 14px;
}
.notifaction-date-time span {
    min-width: 80px;
	display:inline-block;
	padding-right: 10px;
}
.list-view-btn {
    float: right;
}
.list-view-btn a {
    display: inline-block;
    border: 2px solid #E77469;
    color: #E77469;
    padding: 5px 12px;
    border-radius: 7px;
    font-size: 20px;
}
.list-view-btn a:hover {
    background: #E77469;
    color:#fff;
}
.couple-clr, .planner-clr {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #e77469;
    border-radius: 50%;
}
.planner-clr {
    background: #FEC072;
}
span.stats-days {
    font-size: 14px;
    margin-left: 20px;
    display: inline-block;
    line-height: 1;
}
span.stats-days.active span {
    background: #e77469;
}
span.stats-days span {
    width: 14px;
    height: 14px;
    float: left;
    margin-right: 5px;
    background: #C4C4C4;
}
.graph-filter {
    text-align: right;
}
.table-members {
    display: flex;
    justify-content: flex-end;
}
.table-members ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.table-members ul li:first-child {
    margin: 0;
}
.table-members ul li {
    display: inline-block;
    width: 23px;
    margin-left: -10px;
    border: 1px solid #e8736a;
    border-radius: 50%;
    height: 23px;
}
.table-members p {
    background: linear-gradient(122.47deg, #E77469 13.1%, #FEC072 80.56%);
    margin: 0 0 0 5px;
    color: #fff;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: 9px;
    text-align: center;
    padding-top: 5px;
}
.table-sec th {
    font-size: 16px;
    font-weight: 500;
    color: #757575;
    border-bottom: 1px solid #dddddd!important;
    padding: 15px 10px 15px 0;
}
.table-sec td {
    font-size: 16px;
    font-weight: normal;
    vertical-align: middle;
}
td.table-profile-img img {
    border: 1px solid #e8736a;
    border-radius: 50%;
}
.text-right {
    text-align: right;
}
span.menu-icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-image: url(../images/menu-icon.png);
}
span.menu-daily-special-icon {
    background-image: url(../images/daily-special.svg);
}
span.menu-game-day-special-icon {
    background-image: url(../images/game-special.svg);
}
span.menu-happy-hours-special-icon {
    background-image: url(../images/happy-hour.svg);
}
span.menu-on-tap-special-icon {
    background-image: url(../images/on-tap.svg);
}
.navigation-bar ul li .menu-title {
    margin-left: 10px;
}
.dashboard-icon {
    background-position: -8px -13px;
}
.current-page .dashboard-icon, .active .dashboard-icon {
    background-position: -8px -60px;
}
.promotion-icon {
    display: inline-block;
    width: 36px;
    height: 36px;
}
.current-page .promotion-icon, .active .promotion-icon, .navigation-bar > ul > li:has(a.active) > a .promotion-icon {
    background-position: -47px -57px;
}
.request-icon {
    background-position: -202px -12px;
}
.current-page .request-icon, .active .request-icon {
    background-position: -202px -60px;
}
.communication-icon {
    background-position: -124px -15px;
}
.current-page .communication-icon, .active .communication-icon {
    background-position: -124px -60px;
}
.chat-head {
    background: #00ACC0;
    color: #fff;
    text-align: center;
    display: inline-block;
    width: 100%;
    padding: 15px;
	position: relative;
}
.chat-head h5 {
    margin:0;
	font-weight:500;
}
.btn-ligt-blue {
    background: #80d5df;
    color: #fff;
}
.chat-list-head {
    padding: 0 0 25px;
    display: inline-block;
    width: 100%;
    position: relative;
    display: flex;
}
.chat-list-head button {
    float: left;
    background: transparent;
}
.chat-list-head button:hover {
    background: transparent;
}
.chat-list-title {
    float: left;
    width: calc(100% - 50px);
    text-align: center;
}
.chat-list-title p {
    font-size: 12px;
	margin: 0;
}
.chat-list-title h6 {
    margin: 0;
    font-size: 15px;
}
.chat-search {
    background: transparent;
    border-radius: 40px;
    height: 56px;
    border: 1px solid #ddd;
    position: relative;
    width: 100%;
}
.chat-search-inner {
    display: flex;
    position: relative;
    padding: 4px 10px;
    height: 100%;
    align-items: center;
}
.chat-search.active .chat-search-inner {
	display: block;
}
.chat-search.active .search-btn {
	display: none;
}
#chat-area {
    display: flex;
}
.search-btn {
    padding: 7px;
    height: 35px;
    font-size: 14px;
    width: 35px;
    text-align: center;
	display:block;
	cursor: pointer;
}
.chat-search.active{
    top:0;
    height: 100%;
    width: calc(100% - 30px);
    padding-top: 18px;
    background: #fff;
}
.chat-search-inner input {
    background: transparent;
    border-width: 0;
    font-size: 14px;
    width: 100%;
    padding-left: 7px;
    outline: none;
    padding-right: 30px;
}
.chat-search-inner i {
    position: static;
    right: 8px;
    top: 9px;
    opacity: 0.5;
}
.chat-name-alphabet {
    padding-left: 20px;
    font-size: 18px;
	margin-top: 10px;
}
.chat-contact-box {
    padding: 15px 0px;
    display: flex;
    align-items: flex-start;
}
.chat-title {
    color: #8D8D8D;
}
.chat-contact-box:last-child {
    border-width: 0;
}
.chat-contact-box a.show-single-chat {
    display: flex;
    width: 100%;
    color: #212529;
    text-decoration: none;
}
.cperson-img {
    position: relative;
    margin-right: 20px;
    width: 100px;
    height: 100px;
    /* background: rgba(0, 0, 0, 0.08); */
    /* border: 2px solid #000000; */
    /* border-radius: 50%; */
    padding: 3px;
}
.cperson-detail h4 {
    font-size: 22px;
    margin: 0 0 5px;
    
}
.cperson-detail p {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    line-height: 1.2;
    margin: 0;
    
}
.cperson-img span {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 3px solid #fff;
    display: inline-block;
    position: absolute;
    bottom: 1px;
    right: 0px;
}
.cp-active {
    background: #00FF29;
}
.cp-inactive {
    background: #FF0000;
}
.cperson-img img {
    /* border-radius: 50%; */
}
.chat-scroll {
	height: calc(100vh - 270px);
	overflow: auto;
}
#chat-single {
	width: calc(100% - 400px);
}
.chat-action {
    position: absolute;
    right: 30px;
    top: 50px;
}
.chat-action-btn {
    width: 35px;
    height: 35px;
    border-radius: 6px;
    text-align: center;
    padding-top: 7px;
}
.green-txt {
    color: #00FF29;
}
.chat-messages-list {
    height: calc(100vh - 230px);
    overflow: auto;
    margin-top: -8px;
    position: relative;
}
.chat-message-sec {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid #ddd;
    padding: 15px 20px;
    background: #fff;
    z-index: 9;
}
.chat-message-sec .btn {
    width: auto;
    height: auto;
    border-radius: 5px;
    padding: 14px 15px;
    border-width: 0;
    margin-top: 4px;
    background: linear-gradient(122.47deg, #E77469 13.1%, #FEC072 80.56%);
    color: #fff;
    margin-left: 15px;
}
.chat-message-sec .btn:hover {
    background: linear-gradient(122.47deg, #FEC072 13.1%, #E77469 80.56%);
}
.chat-message-sec i {
    margin-left: 15px;
}
.chat-message-sec textarea {
    border-width: 0;
    width: calc(100% - 117px);
    float: left;
    outline: none;
    height: 51px;
    resize: none;
    margin-top: 5px;
    background: #F5F5F5;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    border-radius: 6px;
    padding: 13px;
}
.chat-list {
    height: calc(100% - 53px);
    width: 400px;
}
.dashboard-auto-wrapper.chat-wrapper {
    background: #FFF9F1;
    padding: 0;
}
#cmember-list {
    padding: 30px;
    background: #fff;
}
.single-chat-messages {
    padding: 20px;
    position: absolute;
    bottom: 65px;
    max-height: calc(100% - 100px);
}
.admin-chat img {
    float: right;
    width: 65px;
}
.admin-chat {
    display: inline-block;
    width: 100%;
    margin: 10px 0;
}
.message-box {
    float: right;
    margin-right: 15px;
    border-radius: 8px 0px 8px 8px;
    padding: 10px;
    width: 400px;
    position: relative;
}
.message-gray {
    background: rgb(255 255 255);
}
.message-box p {
    font-size: 17px;
	    margin: 0;
}
.message-gray p {
    color: #757575;
}
.message-gray span {
    color: rgba(117, 117, 117, 0.8);
}
.message-box span {
    font-size: 13px;
}
.member-chat img {
    float: left;
    width: 65px;
}
.message-box.message-blue {
    background: #E77469;
    color: #fff;
    float: left;
    border-radius: 0px 8px 8px 8px;
    margin-right: 0;
    margin-left: 15px;
}
.message-blue p {
	color:#fff;
}
.member-chat {
    display: inline-block;
    width: 100%;
    margin: 10px 0;
}
.message-box.message-blue:before {
    content: "";
    position: absolute;
    border-top: 0px solid;
    border-right: 17px solid #E77469;
    border-bottom: 10px solid transparent;
    left: -17px;
    top: 0;
}
.message-box.message-gray:before {
    content: "";
    position: absolute;
    border-top: 0px solid;
    border-left: 17px solid rgba(217, 206, 180, 0.3);
    border-bottom: 10px solid transparent;
    right: -17px;
    top: 0;
}
#chat-single .chat-list-head {
    padding: 20px 30px 10px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
#chat-single .chat-list-head .chat-contact-box {
    align-items: center;
}
#chat-single .chat-list-head .chat-contact-box p {
    margin-top: 5px;
}
.message-search {
    float: left;
    padding-right: 20px;
}
.message-search input {
    display: none;
}
.show-msg-search {
    margin-top: 5px;
    display: inline-block;
    cursor: pointer;
}
.message-search input {
    display: none;
    border: 1px solid #ddd;
    padding: 10px 20px;
    border-radius: 40px;
    width: 250px;
    margin-top: -6px;
    background: transparent;
    outline: unset;
}
.msg-action {
    position: absolute;
    top: 0;
}
.msg-action {
    position: absolute;
    top: 0;
    left: -12px;
}
.message-box.message-blue .msg-action {
    right: -12px;
    left: auto;
}
.msg-action-icon {
    color: rgba(117, 117, 117, 0.8);
    cursor: pointer;
}
.msg-mem-action {
    display: inline-block;
    position: relative;
}
.action-tooltip {
    position: absolute;
    background: #fff;
    width: 100px;
    right: 25px;
    top: 0;
    padding: 5px 0;
    border-radius: 5px;
    z-index: 99;
    display: none;
}
.action-tooltip a {
    display: inline-block;
    padding: 8px 10px;
    color: inherit;
    width: 100%;
}
.action-tooltip a:hover {
    background-color: rgba(217, 206, 180, 0.3);;
}
.action-tooltip:before {
    content: "";
    position: absolute;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 12px solid rgba(255, 255, 255, 1);
    right: -11px;
    top: 3px;
}
.notificaion-inner {
    position: relative;
}
.unread-noti-btn {
    color: #e77469;
    text-decoration: underline;
    font-size: 14px;
    position: absolute;
    right: 0;
    top: 0;
}
.unread-noti-btn:hover {
    text-decoration: none;
    color: #e77469;
}
.table-top-btns {
    justify-content: right;
}
.dashboard-btns {
    display: flex;
}
.dashboard-btns .btn {
    padding: 13px 20px;
    width: 150px;
    border-radius: 10px;
}
.table-user img {
    width: 40px;
    margin-right: 5px;
}
.dashboard-table.table-sec td {
    font-size: 14px;
}

.dashboard-table.table-sec th {
    font-weight: 500;
}
.table-actions img {
    max-height: 16px;
}
.table-actions a {
    display: inline-block;
    margin-left: 8px;
}
.table-actions a:hover {
    opacity: 0.7;
}
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 22px;
    max-width: 50px;
}
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 3px;
    top: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
  
  .switch input:checked + .slider {
    background-color: #e77469;
  }
  
  .switch input:focus + .slider {
    box-shadow: 0 0 1px #e77469;
  }
  
  .switch input:checked + .slider:before {
    -webkit-transform: translateX(27px);
    -ms-transform: translateX(27px);
    transform: translateX(27px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .table-filter {
    margin-right: 20px;
  }
  .dashboard-btns button.btn.btn-tansparent {
    border: 1px solid rgba(0,0,0,0.5);
    color: #e77469;
    background: transparent;
}
.dashboard-btns button.btn.btn-tansparent:hover {
  border: 1px solid #e77469;
  color: #fff;
  background-color: #e77469;
}
.custom-modal.show {
    display: table;
}
.custom-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    display: none;
    left: 0;
    top: 0;
    z-index: 99999;
}
.custom-pop-inner {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
}
.popup-large .modal-dialog .custom-pop-box {
    width: 900px;
}
.custom-pop-box {
    background: #fff;
    padding: 30px;
    border-radius: 20px;
    margin: 0 auto;
}
.popup-medium .modal-dialog {
    max-width: 95%;
    width: 629px;
}
.popup-large .modal-dialog {
    max-width: 95%;
    width: 900px;
}
.modal-backdrop {
    z-index: 99999;
}
.modal {
    z-index: 999999;
}
.pop-title {
    font-size: 24px;
    margin-bottom: 20px;
}
button.btn-close {
    position: absolute;
    right: 20px;
    top: 32px;
    box-shadow: unset!important;
    outline: unset;
}
button.btn.btn-long {
    width: 200px;
    height: 50px;
    border-radius: 10px;
}
.pop-form label {
    color: #757575;
    font-size: 18px;
    width: 100%;
}
.pop-form textarea.form-control {
    min-height: 80px;
}
.msgarea-msg {
    text-align: right;
    font-size: 12px;
    margin-top: 5px;
}
.pop-form label.switch {
    width: 50px;
}
.pop-form .form-control {
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 6px;
    height: 52px;
}
.form-sec .form-control {
    border: 1px solid rgba(0, 0, 0, 0.28);
    box-sizing: border-box;
    border-radius: 12px;
    height: 72px!important;
    appearance: none;
    color: #828282;
    font-size: 17px;
}
.form-sec label {
    margin-bottom: 5px;
    width: 100%;
}
.dashboard-btns .btn:first-child {
    margin-right: 20px;
}
.uploaded-img {
    width: 150px;
    margin-top: 10px;
    max-height: 150px;
    overflow: hidden;
}
.uploaded-img img {
    border-radius: 15px;
}
.file-field span {
    background: #E77469;
    border-radius: 0px 12px 12px 0px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    color: #fff;
    width: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.file-field {
    position: relative;
}
.file-field input[type="file"] {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    cursor: pointer;
    opacity: 0;
}
select.form-control {
    background-image: url(../images/select-icon.svg);
    background-repeat: no-repeat;
    background-position: 98% center;
}
.table-filter button.btn.btn-tansparent {
    margin: 0;
}
.common-message-pop {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    display: none;
    left: 0;
    top: 0;
    z-index: 9999999;
}
.common-message-pop.show{
	display: table;
}
.common-pop-inner {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
}
.common-pop-box {
    width: 500px;
    background: #fff;
    text-align: center;
    padding: 30px;
    border-radius: 20px;
    margin: 0 auto;
}
.error-icon {
    color: #ff8585;
    font-size: 100px;
}
.success-icon{
    color: #35b32d;
    font-size: 100px;
}
.message-pop-button {
    margin-top: 30px;
}
.message-pop-button button {
    padding: 10px 20px;
}
.warning-icon {
    color: #f8bb86;
    font-size: 100px;
}
.message-pop-button button.btn.btn-default {
    background-color: #c1c1c1;
    color: #fff;
    border-color: #c1c1c1;
}
.message-title.success-title {
    color: #39d78d;
    text-transform: uppercase;
    font-weight: 900;
    margin: 20px 0;
}
.plan-pop {
    position: absolute;
    background: #fff;
    width: 800px;
    right: 0;
    box-shadow: 0px 4px 4px 3px rgb(0 0 0 / 4%);
    border-radius: 20px;
    padding: 5px 25px 25px;
    top: 70px;
    display: none;
    max-height: 70vh;
    overflow: auto;
    border: 1px solid #ddd;
}
.plan-pop-inner {
    position: relative;
}
.plan-title {
    background: #e77469;
    color: #fff;
    text-align: center;
    padding: 8px;
    border-radius: 15px 15px 0 0;
}
.plan-price {
    text-align: center;
    margin: 10px 0;
}
.plan-tag {
    display: block;
    width: 70%;
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
}
.plan-tag.red-tag {
    color: #E77469;
    background: linear-gradient(0deg, rgba(231, 116, 105, 0.1), rgba(231, 116, 105, 0.1)), #FFFFFF;
}
.plan-desc {
    color: #969BA0;
    font-size: 15px;
    text-align: center;
    padding: 5px 20px 15px;
    line-height: 18px;
}
.plan-tag.orange-tag {
    background: rgba(254, 192, 114, 0.2);
    color: #FEC072;
}
.plan-tag.green-tag {
    background: rgba(57, 215, 141, 0.2);
    color: #39D78D;
}
#filter-pop {
    position: fixed;
    right: -500px;
    top: 0;
    width: 500px;
    height: 100%;
    background: #fff;
    z-index: 99999;
    transition: 0.4s;
    padding: 40px 40px 40px;
}
.filter-form-btns {
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 30px;
}
.filter-pop-inner {
    position: relative;
    min-height: 450px;
    height: 100%;
}
#filter-pop.show {
    right: 0;
}
.filter-head h4 {
    border-bottom: 2px solid;
    font-size: 22px;
    /* padding-bottom: 5px; */
    padding-right: 5px;
    /* margin-top: 30px; */
    margin-right: 10px;
    margin-bottom: 0;
}
.popup-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    z-index: 99999;
    display: none;
}
.filter-head {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 40px;
    align-items: flex-start;
}
.filter-body .form-control {
    background: #F3F3F3;
    border-color: #F3F3F3;
    height: 56px;
    border-radius: 20px;
}
.input-icon {
    position: relative;
}
.input-icon input {
    padding-left: 40px;
}
.input-icon img {
    position: absolute;
    right: 10px;
    top: 23px;
}
.input-icon i, .input-icon .input-icon-alt {
    position: absolute;
    left: 15px;
    top: 18px;
    color: #B6B6B6;
}
.filter-form-btns button.btn {
    border-radius: 20px;
    height: 60px;
}
.times-btn {
    background: transparent;
    border-width: 0;
    padding: 0;
    font-size: 27px;
    color: #000;
}
.times-btn:hover {
    opacity: 0.8;
}
.modal-content {
    border-radius: 20px;
}
.table>tbody tr:hover {
    background: rgba(231, 116, 105,0.1);
}
.dashboard-auto-wrapper.profile-wrapper {
    padding: 0px;
}
.profile-cover img {
    width: 100%;
}
.profile-header {
    padding: 0 40px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
    position: relative;
}
.profile-img img {
    border: 10px solid #fff;
    border-radius: 50%;
}
.profile-img {
    border-radius: 50%;
    margin-top: -145px;
    margin-bottom: 20px;
}
.profile-htitle {
    font-size: 22px;
    font-weight: 500;
}
.profile-header span {
    color: #757575;
    font-size: 18px;
    font-weight: 500;
}
.profile-hname {
    width: 200px;
    position: relative;
    margin-right: 20px;
    margin-left: 20px;
}
.profile-hname:after {
    content: "";
    position: absolute;
    height: 50px;
    width: 1px;
    border-right: 1px solid rgba(0,0,0,0.3);
    top: 9px;
    right: 0;
}
.profile-follower {
    width: 170px;
    text-align: center;
}
.profile-header-info {
    display: flex;
    align-items: center;
}
.profile-hmenu a {
    color: #8F8F8F;
    display: inline-block;
    padding: 0 25px;
}
.profile-hmenu a.active {
    color: #757575;
    font-weight: 500;
}
.profile-bottom-box {
    max-width: 1100px;
    margin: 0 auto;
}
.profile-inner-wrapper {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 30px 25px;
    margin-top: 40px;
}
.wrapper-head p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 20px;
    line-height: 23px;
}
.profile-contact-box {
    color: rgba(0, 0, 0, 0.5);
    font-size: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.profile-contact-box span {
    width: 30px;
    height: 33px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 20px;
}
.btn.btn-primary.btn-md {
    padding: 12px;
    border-radius: 10px;
}
.follwer-list .chat-contact-box {
    align-items: center;
    position: relative;
    padding: 10px 0px;
}
.follwer-list .chat-contact-box .cperson-img img {
    max-width: 44px;
    max-height: 65px;
    border-radius: 50%;
}
.follwer-list .chat-contact-box .cperson-detail h4 {
    font-size: 15px;
    margin: 0;
}
.follwer-list .chat-contact-box .cperson-detail p {
    font-size: 13px;
}
.pfollower-tab {
    background: rgba(254, 192, 114,0.1);
    display: flex;
    border-radius: 10px;
}
.pfollower-tab a {
    flex: 0 0 50%;
    text-align: center;
    color: inherit;
    font-size: 14px;
    padding: 5px;
    border-radius: 10px;
}
.pfollower-tab a.active {
    background: #e77469;
    color: #fff;
}
.follwer-list .chat-contact-box .msg-action {
    left: auto;
    right: 0;
    top: 24px;
}
.follwer-list {
    margin-top: 20px;
}
.follwer-list .action-tooltip {
    background: rgba(254, 192, 114,0.1);
}
.follwer-list .action-tooltip:before {
    border-left: 12px solid rgba(254, 192, 114,0.1);
}
.pink-link {
    color: #e77469;
    font-size: 15px;
}
.follower-list-link {
    margin-top: 10px;
}
.profile-about.profile-inner-wrapper .plan-box {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-width: 0;
    margin-top: 20px;
}
.profile-feed-box {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgb(0 0 0 / 10%);
    border-radius: 12px;
    padding: 30px 25px 0;
    margin-top: 40px;
    display: inline-block;
    width: 100%;
}
.profile-feed-box .chat-contact-box {
    align-items: center;
    padding: 0;
    margin-bottom: 20px;
}
.profile-feed-box .chat-contact-box .cperson-detail h4 {
    font-size: 18px;
}
.profile-feed-box .chat-contact-box .cperson-detail p {
    font-size: 16px;
}
.feed-desc {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    line-height: 23px;
}
.feed-images {
    display: flex;
    margin-top: 15px;
}
.feed-images img {
    width: 100%;
}
.feed-large {
    width: 73%;
}
.feed-small {
    width: 30%;
}
.feed-small-box:last-child {
    margin-top: 10px;
}
.feed-large img {
    max-width: 97.5%;
}
.feed-action {
    display: flex;
    align-items: center;
    margin: 15px 0;
}
.feed-action-box {
    margin-right: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.feed-action span {
    margin-right: 5px;
    display: inline-block;
    width: 23px;
    height: 21px;
    background-repeat: no-repeat;
}
.feed-footer {
    display: flex;
    padding: 15px 0 15px 30px;
    margin: 0 -25px;
    border-top: 1px solid #ddd;
    align-items: center;
}
.feed-footer-members ul li:first-child {
    margin: 0;
}
.feed-footer-members ul li {
    display: inline-block;
    width: 30px;
    margin-left: -10px;
    border: 1px solid #ffffff;
    border-radius: 50%;
    height: 30px;
}
.feed-footer-members ul li img {
    width: 100%;
}
.feed-footer-members p {
    margin: 0 0 0 5px;
    border-radius: 50%;
    font-size: 14px;
    text-align: center;
    padding-top: 5px;
}
.feed-footer-members ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.feed-footer-members {
    display: flex;
    align-items: center;
}
.feed-footer-members p {
    padding: 0;
}
.feed-footer-box:last-child {
    margin-left: 50px;
    font-size: 14px;
}
.feed-footer-box {
    color: #626262;
}
.error {
    color: red;
    font-size: 15px;
    display: none;
}
.nav-close {
    display: none;
}
.table-action .btn {
    display: block;
    height: 28px;
    padding: 0px;
}
.table-action .btn + .btn {
    margin-top: 5px;
}
.common-table td.table-action {
    padding: 10px 15px 10px 20px;
}
.create-from-steps {
    width: 100%;
    padding-left: 40px;
    max-width: 100%;
    padding-bottom: 40px;
}
.create-from-steps h4 {
    font-weight: 600;
}
.form-check {
    color: #757575;
    font-size: 14px;
}
.next-step-btn button.btn {
    width: 150px;
    height: 50px;
}
.form-check-input:focus {
    border-color: #000;
    outline: 0;
    box-shadow: unset;
}
.create-from-steps {
    display: none;
}
.create-from-steps.active {
    display: block;
}
.form-label {
    color: #757575;
    font-size: 18px;
    font-weight: 500;
}
.create-from-steps .form-control {
    height: 50px;
    border-radius: 6px;
}
.input-right-icon {
    position: relative;
}
.input-right-icon svg {
    position: absolute;
    right: 10px;
    top: 17px;
}
.time-add-more {
    padding-top: 33px;
}
.time-add-more .btn {
    height: 50px;
}
.create-from-btn button.btn {
    width: 150px;
    height: 50px;
}
.create-from {
    position: relative;
}
button.step-back-btn {
    background: transparent;
    border-width: 0;
    outline: 0;
    position: absolute;
    left: 0;
    font-size: 22px;
    top: -5px;
}
.profile-bar {
    background: #000;
    margin: -25px 0 30px -25px;
    width: calc(100% + 50px);
    border-radius: 10px 10px 0 0;
    padding: 15px 25px;
}
.profile-bar p {
    margin: 0;
    color: #fff;
}
.profile-img-box {
    align-items: center;
    padding: 0;
    display: flex;

}
.edit-profile-btn .edit-btn-icon {
    padding: 3px 5px 3px 10px;
    border-left: 1px solid #fff;
    margin-left: 10px;
}
.create-from-btn button.btn.edit-profile-btn {
    width: auto;
}
.create-from-btn button.btn.edit-profile-btn span {
    padding: 0 10px;
}
.subscription-btn .btn {
    background: #1e8400;
    border-color: #1e8400;
    padding: 10px 20px;
}
.plan-box {
    border-radius: 15px;
    box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 10%);
    padding: 30px;
    margin-top: 30px;
    border: 1px solid transparent;
    background: #fff;
}
.current-plan-txt {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}
.profile-membership-plan .plan-box {
    margin: 0;
    height: 29rem;
}
span.price-con {
    font-size: 40px;
    font-weight: 600;
}
.plan-box.selected {
    border: 1px solid;
}
.plan-box.selected .choose-plan-btn {
    background: #000;
    color: #fff;
}
.plan-box.selected .choose-plan-btn:hover {
    background: #000;
    color: #fff;
}
ul.plan-point {
    margin-top: 15px;
    list-style: none;
    padding: 0;
}
ul.plan-point li {
    padding-left: 35px;
    position: relative;
    font-size: 14px;
    margin-bottom: 10px;
    text-align: left;
}
ul.plan-point li:before {
    content: "";
    width: 22px;
    height: 22px;
    position: absolute;
    left: 0;
    top: 3px;
    background-size: 100% 100%;
}


.plan-point input{
    padding:12px
}

.plan-box h4 {
    font-weight: 600;
    font-size: 20px;
}
.popup-overlay.show {
    display: block;
}
.common-pop.modal.show {
    z-index: 99999;
}
/* .fade.modal-backdrop {
    z-index: 99999;
} */
.common-pop .modal-content {
    border-radius: 20px;
}
.create-from-btn {
    display: flex;
    column-gap: 15px;
}
.common-pop-box.created-message-box {
    padding: 100px 30px;
    width: 450px;
    background-image: url(../images/success-bg.jpg);
}
.common-pop-box.created-message-box h2 {
    font-size: 50px;
    font-weight: 600;
}
.common-pop-box.created-message-box .message-txt {
    font-size: 24px;
}

/*--------------------------------------------- Responsive ---------------------------------*/
@media screen and (max-width: 1400px) {
    .profile-info {
        margin-left: 30px;
    }
}
@media screen and (max-width: 1300px) {
    .search-open-btn {
        display: block;
        padding: 10px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        font-size: 13px;
        text-align: center;
        background: linear-gradient(122.47deg, #E77469 13.1%, #FEC072 80.56%);
        color: #fff;
        margin-top: 3px;
    }
    .nav-search {
        width: auto;
    }
    .search-inner {
        position: fixed;
        top: 0;
        display: none;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
    .search-inner.show {
        display: block;
    }
    .search-close {
        display: block;
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 26px;
    }
    .search-form {
        display: block;
        width: 500px;
        margin: 100px auto 0;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
        max-width: 90%;
    }
}

@media screen and (max-width: 1100px) {
.side-nav {
    /* left: -250px!important; */
    top: 0!important;
    height: 100vh;
}
.dashboard-inner {
    width: 100%;
    margin: 90px 0 0 0px;
    padding: 30px;
}
.nav-sm .dashboard-inner {
    margin-left: 0px;
    width: 100%;
}
.nav-sm .top-nav {
    padding-left: 40px;
}
.dashboard-logo img.large-logo {
    display: none;
}
.dashboard-logo img.small-logo {
    display: block;
}
.dashboard-logo {
    width: auto;
}
.nav-sm .dashboard-logo {
    font-size: 0;
    width: auto;
}
.nav-sm .side-nav {
    width: 250px;
    padding-left: 10px;
}
.side-nav.show {
    left: 0!important;
}
.nav-sm .navigation-bar span.menu-title {
    display: block;
}
.menu-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
}
.menu-overlay.show {
    display: block;
}
.nav-close {
    display: none;
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 99999999;
    color: #fff;
    font-size: 25px;
}
.search-inner.show {
    display: block;
}
.table-responsive table.table {
    min-width: 900px;
}
/* .side-nav.show .nav-close {
    display: block;
} */
}


@media screen and (max-width: 767px) {
.top-nav {
    padding: 10px;
}
.message-notificaion {
    margin-top: -3px;
    margin-bottom: -3px;
}
.nav-search {
    margin-left: 20px;
}
.profile-info {
    margin-left: 20px;
}
.profile-name {
    display: none;
}
.profile-info {
    width: auto;
    margin-top: 0;
}
.profile-info img {
    /* width: 45px; */
    margin-right: 5px;
}
.dashboard-logo img {
    max-height: 45px;
}
.notification-icon {
    width: 50px;
    height: 50px;
    padding-top: 11px;
}
.notification-icon img {
    max-height: 20px;
}
.dashboard-inner {
    margin: 55px 0 0 0px;
    padding: 30px 20px;
}
.profile-tooltip {
    top: 55px;
    right: 0;
}
.profile-tooltip a {
    padding: 10px 15px;
}
.notification-pop {
    width: 300px!important;
    right: -220px;
    padding: 5px 15px 25px;
    top: 55px!important;
}
.notification-con {
    width: calc(100% - 37px);
}
.notification-con p {
    font-size: 14px;
}
.dashboard-btns .btn {
    padding: 10px 15px;
    width: 120px;
    border-radius: 10px;
    font-size: 15px;
}
.table-filter {
    margin-right: 10px;
}
.wrapper-head {
    text-align: center;
}
.table-top-btns {
    justify-content: center;
    margin-top: 10px;
}
}

@media screen and (max-width: 574px) {
.search-open-btn {
    padding: 8px;
    width: 35px;
    height: 35px;
    font-size: 13px;
    margin-top: 0;
}
/* .profile-info img {
    width: 35px;
} */
.profile-icon {
    margin-top: 4px;
}
.notification-icon {
    width: 40px;
    height: 40px;
    padding-top: 6px;
}
.message-notificaion {
    margin-left: 0px;
    margin-right: 5px;
    margin-top: 0;
    margin-bottom: 0;
}
.notification-icon span {
    width: 15px;
    height: 15px;
}
.profile-info {
    margin-left: 0;
}
.dashboard-logo img {
    max-height: 35px;
    margin-right: 5px;
}
.toggle-menu img {
    max-height: 35px;
}
.nav-search {
    margin-left: 10px;
}
.notification-pop {
    right: -142px;
    top: 45px!important;
}
.notification-title {
    font-size: 15px;
    margin-top: 15px;
}
.notificaion-box {
    padding: 10px 0;
}
.profile-tooltip {
    top: 45px;
}
}

tbody.empty_row tr td {
    text-align: center;
    background: #e7746912 !important;
}

.table-outer-loader{
    display: flex;
    justify-content: center;
    height: 50vh;
    align-items: center;
}
.table-inner-loader{
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
}


.upload_pic img {
    vertical-align: middle;
    width: 100px;
    height: 100px;
    border-radius: 50%;
object-fit: cover;

}

.react-confirm-alert-body{
    box-shadow: 0 20px 75px rgb(0 0 0 / 40%) !important;
    color: #322a2a !important;
    text-align: center !important;
}
.react-confirm-alert-button-group {
    display: inherit !important;
}

.document_upload .btn span {
    color: #fff !important;
}

span.pending_status {
    color: #e77469;
}

span.active_status {
    color: #52d32c;
}
td.discount_type {
    text-transform: capitalize;
}

/* .upload_pic img {
    height: 92px;
    margin: 0px;
    max-width: 92px;
    border-radius: 50%;
} */

.search_Table{
    padding-bottom: 14px;
}

.sr-combo-inputs {
  margin: 20px 0;
}

.sr-input {
  font-size: 16px;
}

.sr-card-element {
  padding-top: 12px;
}

.btn {
  font-size: 16px;
}

#messages {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
    display: none; /* hide initially, then show once the first message arrives */
    background-color: #0A253C;
    color: #00D924;
    padding: 20px;
    margin: 20px 0;
    border-radius: var(--radius);
    font-size:0.7em;
}


.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    padding: 10px 14px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border-radius: 4px;
    background: white;
  }
  
.StripeElement--focus {
box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
-webkit-transition: all 150ms ease;
transition: all 150ms ease;
}

.time-form-control{
    font-size: 16px;
    padding: 7px;
    width: 100%;
    height: 40px;
}

.time-form-group{
    margin-right: 10px;
    padding: 14px 0px;
}

.mt-30{
    margin-top: 30px;
}

/* 13 sept 23 */
.replies-popup span{
    position: sticky;
    bottom: 7px;
}
.replies-popup thead{
    position: sticky;
    top: 0;
}
.replies-popup .table-responsive tbody tr td {
    padding: 11px 0;
}
/* .replies-popup span {
    background-color: #212529;
    color: #fff;
    padding: 10px 13px;
    border-radius: 9px;
} */
.replies-popup table{
    margin-bottom: 26px;
    width: 100%;
}
.replies-popup .table-responsive{
    height: 251px;
    
}
.replies-popup .table-responsive thead tr th {
    padding: 10px 0;
    /* border-top: 1px solid #cfcfcf;
    border-bottom: 1px solid #cfcfcf; */
    background-color: #fff;
}
.replies-popup table tbody tr{
     border-top: 1px solid #cfcfcf;
    border-bottom: 1px solid #cfcfcf;
}
.replies-popup span {
    position: sticky;
    bottom: 7px;
    color: #b72727;
    display: flex;
    justify-content: end;
    margin: 10px;
    /* display: none; */
}

img.img-premium {
    width: 25px;
    margin-left: 7px;
}
.searched-items .modal-content table{
    width: 100%;
}
.searched-items table tbody tr{
    border-top: 1px solid #cfcfcf;
    border-bottom: 1px solid #cfcfcf;
}
.searched-items table thead th{
    position: sticky;
  top: 0;
  background-color: #fff;
}
.searched-items table thead th{
    padding: 10px 0;
 
    cursor: pointer;
}
.searched-items table tbody tr td {
    padding: 12px 0;
}
/* scrollbar */
/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(202, 202, 202); 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ccc; 
    border-radius: 10px;
  }
  
  .noti-user-name .mt-2 span:last-child {
    background-color: #f1f1f1;
    padding: 3px;
    color: #000;
    padding: 6px 13px;
    border-radius: 4px;
    font-size: 14px;
}
.noti-user-name .mt-2 {
    margin-top: 22px !important;
}

.message-notificaion {
    display: inline-block;
    float: right;
    margin-left: 20px;
    margin-top: -6px;
    position: relative;
}

.notification-icon {
    background: rgba(0, 0, 0, 0.1);
    width: 56px;
    height: 56px;
    border-radius: 20px;
    text-align: center;
    padding-top: 14px;
    position: relative;
    cursor: pointer;
}

.notification-icon span {
    width: 20px;
    height: 20px;
    position: absolute;
    display: inline-block;
    background: #000;
    border-radius: 50%;
    border: 3px solid #FBFBFB;
    top: 0;
}

.notification-pop {
    position: absolute!important;
    background: #fff;
    width: 430px!important;
    right: 0;
    box-shadow: 0px 4px 4px 3px rgb(0 0 0 / 4%);
    border-radius: 20px;
    padding: 5px 25px 25px;
    top: 70px!important;
    max-height: 70vh;
    overflow: auto;
    border: 1px solid #ddd;
    left: unset!important;
}

.notificaion-inner {
    position: relative;
}

.notification-title {
    font-size: 19px;
    margin-top: 20px;
}

.unread-noti-btn {
    color: #e77469;
    text-decoration: underline;
    font-size: 14px;
    position: absolute;
    right: 0;
    top: 0;
}

.notificaion-box {
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
    display: inline-block;
    width: 100%;
}

.notification-con {
    width: calc(100% - 65px);
    float: left;
    padding-left: 15px;
}

.notification-con p {
    color: #000;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: 0px;
    font-size: 14px;
}

.notifaction-date-time {
    color: #969BA0;
    font-size: 14px;
}

.notifaction-date-time span {
    min-width: 80px;
    display: inline-block;
    padding-right: 10px;
}
.img-left {
    text-align: end;
}


.tooltip-text {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    width: 100px;
    color: white;
    font-size: 12px;
    background-color: #192733;
    border-radius: 10px;
    padding: 10px 15px 10px 15px;
  }
  
  .hover-text:hover .tooltip-text {
    visibility: visible;
  }
  
  #top {
    top: -40px;
    left: -50%;
  }
  
  #bottom {
    top: 25px;
    left: -50%;
  }
  
  #left {
    top: -8px;
    right: 120%;
  }
  
  #right {
    top: -43px;
    right: -26px;
    text-align: center;
}
  
  .hover-text {
    position: relative;
    
  }

  /* 19-09-2023 */

  .fix-notification h5 {
    font-size: 21px;
    font-weight: 600;
}
.fix-notification img {
    max-width: 213px;
    width: 100%;
    height: 260px;
    object-fit: cover;
}
.title-box h5 {
    font-size: 19px;
}
.popup-deatils h5 {
    text-align: initial;
}
.popup-deatils{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.popup-deatils {
    padding: 1rem;
}
.popup-deatils p {
    text-align: initial;
}
.sendNotification-btn button.btn {

    height: 41px;
}
h5.followers {
    font-size: 14px;
}
.main-div-tab {
    height: 704px;
}
ul.pagination {
    /* position: fixed;
    bottom: 0px; */
    --bs-pagination-active-bg: #000000;
    --bs-pagination-active-border-color: #000000;
    --bs-pagination-color: #000000;
}
.img-thumbnail {
    max-width: 100%;
    height: 73px !important;
}

.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #FFFFFF;
    border: none !important ;
    border-radius: 5px;
    line-height: 25px;
    height: 100% !important;
    width: 100% !important ;
    outline: none;
}
img.img-premium.img-king {
    width: 22px;
    margin-right: 13px;
    
}

.filter-box {
    margin-right: 8px;
}

/* .dash-notification-list {
    padding: 34px 0px;
} */

.otp-field {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 3px 11px; */
}

.otp-field input {
    width: 67px;
    font-size: 32px;
    padding: 3px;
    text-align: center;
    border-radius: 5px;
    margin: 2px;
    border: 2px solid #212529;
    background: #ffffff;
    font-weight: bold;
    color: #000;
    outline: none;
    transition: all 0.1s;
    margin-bottom: 19px;
    margin-right: 2rem;
}



.disabled {
    opacity: 0.5;
}

button.btn.btn-dark.signin-btn.send-otp {
    width: 100%;
    padding: 12px 11px;
    max-width: 388px;
    margin-right: 2rem;
}

.otp-verification-link {
    position: absolute;
    text-align: center;
    width: 100%;
    color: #828282;
    left: 0;
    bottom: 30%;
}

.graph-set {
    margin-right: 48px;
}
.graph-set p {
    color:#35b32d;
}
.graph-set a {
    color:#35b32d;
}
.graph-sets p {
    color:#35b32d;
    margin-left:-1px;
    position: relative;

    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    
}
.password p{
    color: #eb1919;    
        margin-top: -22px;
        margin-bottom: 0rem;
    
}

.graph-sets {
    margin-right: 12px;
}
a.ant-picker-now-btn {
    margin: 0px 6px;
}


.password-input-field {
  position: relative;
}

.password-input-field .icon.Password-hide {
    position: absolute;
    content: '';
    top: 56%;
    right: 12px;
  }
  label.profile-file-upload {
    cursor: pointer;
    display: block;
    color:#282041de;
}
button.mt-3.btn.btn-dark.clear-btn {
    padding: 2px 20px;
}
.clear-input {
    display: flex;
    justify-content: end;
    align-items: center;
}


.map-btn span:hover {
    color: #009688;
}
.map-btn svg {
    width: 100%;
    max-width: 29px;
    height: 17px;
}

.map-btn {
    cursor: pointer;
    margin-top: 6px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    max-width: 44%;
}

.map-btn span {
    font-size: 14px;
    color:#4CAF50;
    
   
}
.map-btn.latitude {
    width: 100%;
    max-width: 41%;
}
span.latlong {
    font-size: 14px;
    color: #4CAF50;

}

.restaurant-address-map {
    border: 1px solid #ffeb3b24;
    padding: 19px 25px;
    border-radius: 22px;
    background: #ffeb3b17;
}

span.latitute {
    position: absolute;
    right: 0;
    top: 0;
    color: #4CAF50;
    font-size: 16px;
}
.input-filed--views {
    position: relative;
}



.Restaurant-Longitude {
    position: relative;
}
.Restaurant-Latitude {
    position: relative;

}

@media (max-width: 1394px) { 
    .map-btn {
        max-width: 62%;
    }
    .map-btn.latitude {
        max-width: 59%;
    }

 }

 @media (max-width: 1632px) { 
    .map-btn {
        max-width: 49%;
    }
    .map-btn.latitude {
        max-width: 46%;

 }
}



@media (max-width: 1509px) { 
    .map-btn {
        max-width: 57%;
    }
    .map-btn.latitude {
        max-width: 54%;

 }
}
@media (max-width: 1466x) { 
    .map-btn.latitude {
        max-width: 52% !important;
    }
    .map-btn {
        max-width: 56% !important;
    }
}

@media (min-width: 1254px) and (max-width: 1310px) { 

    .map-btn {
        max-width: 64%;
    }

    .map-btn.latitude {
        max-width: 62%;
    }
    

 }

 @media (min-width:1735px ) { 
    .map-btn {
        max-width: 42%;
    }

    .map-btn.latitude {
        max-width: 39%;
    }

 }
 @media (min-width: 1800px) and (max-width: 1889px) { 
    .map-btn {
        max-width: 40%;
    }

    .map-btn.latitude {
        max-width: 37%;
    }
  } 

  @media (min-width:1889px) and (max-width: 2047px ) { 
    .map-btn {
        max-width: 37% !important;
    }

    .map-btn.latitude {
        max-width: 34% !important;
    }
  } 
  @media (min-width: 2047px) {
    .map-btn {
        max-width: 32% !important;
    }
    .map-btn.latitude {
        max-width: 30% !important;
    }

  }

  .popup-medium .modal-dialog {
    max-width: 100%;
    width: 945px !important;
}
   

/* .side-nav.collapsed span.menu-title {
    display: none;
} */
  
/* ResponsiveSidebar.css */
.side-nav {
    /* ... (Your existing sidebar styles) */
    transition: all 0.3s ease;
  }

  .toggle-icon {
  display: none;
  }
  
  
  
  @media (max-width: 1024px) {
   
  
    .side-nav.collapsed {
      width: 0; /* Sidebar is completely hidden on small screens */
    }
    .toggle-icon {
        cursor: pointer;
        padding: 10px;
        font-size: 30px;
        top: 0;
        position: absolute;
        right: 0;
        display: block;
      }

      .side-nav.collapsed {
        width: 0px !important; /* Adjust as needed */ 
        padding-left: 0px !important;
      }

      h3.page-name {
        margin-top: 0;
    }

    .top-nav .dashboard-logo {
        display: none;
    }
      

    .profile-info img {
        width: 40px;
        margin-right: 12px;
        height: 40px;
        margin-left: 6px;
    }

    .notification-icon {
        width: 45px;
        height: 45px;
    }
      
    .notification-icon img {
        margin-top: -8px;
    }

    .navigation-bar h4 {
        margin-top: 28px;
        position: absolute;
        left: 0;
        top: 25px;
    }
  }


  .side-nav.collapsed {
    width: 300px ;
    padding-left: 20px;
}
img.update-img-colum {
    width: 211px;
    height: 188px;
    aspect-ratio: 3 / 2;
    cursor: pointer;
    border-radius: 20px;
    margin-top: 10px;
    position: relative;
    box-shadow: 0 24px 34px rgba(0, 0, 0, .2);
    object-fit: cover;
}
.approved{
    color: #1E8400 ;
    cursor: pointer;
}
.rejected{
    color: #DA0000;
    cursor: pointer;
}

.adnin-message{
        word-wrap: break-word;
        white-space: normal;
        overflow-wrap: break-word;
}

.notification-item {
    position: relative;
  }
  
  .message-preview {
    display: inline-block;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .message-preview::after {
    content: attr(data-full-text);
    position: absolute;
    top: 100%;
    left: 0;
    white-space: normal;
    display: none;
    background-color: #fff;
    padding: 5px;
    border: 1px solid #ccc;
    z-index: 10;
  }
  
  .message-preview:hover::after {
    display: block;
  }
  

  /* Filename - App.css */



.geeks {
	color: green;
}

.text {
	display: inline;
	width: 90%;
}

.read-or-hide {
	color: rgb(192, 192, 192);
	cursor: pointer;
}



.Offering-board input.form-check-input {
    width: 25px;
    height: 25px;
    cursor: pointer;
}


.Offering-board label.form-check-label {
    padding-top: 6px;
    padding-left: 9px;
    font-size: 16px;
    color: #000;
    font-weight: 500;
}

.Offering-board .form-check-input:checked {
    background-color: #000;
    border-color: #000;
    box-shadow: none;

}

.Offering-board .form-check-input:focus{
    box-shadow: none;
    border-color: #000;
}

.rdw-option-wrapper {
    border: 1px solid #F1F1F1;
    padding: 5px;
    min-width: 33px;
    height: 25px;
    border-radius: 2px;
    margin: 0 4px;
    display: flex;

    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
}



.rdw-emoji-modal{
    justify-content: center;

}

@media (min-width:1427px) and (max-width: 1545px) { 


    .rdw-option-wrapper {
        border: 1px solid #F1F1F1;
        padding: 11px !important;
        min-width: 34px !important;

    }



    .rdw-emoji-modal {
        overflow: auto;
        position: absolute;
        top: 30px;
        left: -44px !important;
    }
 }

 @media (min-width:1546px) and (max-width: 1778px) { 

    .rdw-emoji-modal {
        overflow: auto;
        position: absolute;
        top: 35px;
        left: -121px !important;
    }

 }

 @media (min-width:1024px) and (max-width: 1366px) { 
    .rdw-emoji-modal {
        overflow: auto;
        position: absolute;
        top: 35px;
        left: -48px !important;
    }


 }