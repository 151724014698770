body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Toastify__toast-container--top-right{
  z-index: 999999 !important;
}


.ant-picker-footer {
  position: relative;
}

.custom-close-button {
  position: absolute;
  top: 50%;
  left: 15%;
  transform: translate(-50%, -50%);
  background: #000;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  color: #1677ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
 transition: color 0.3s;
}
.input-right-icon.bold_placeholder input::placeholder {
  font-weight: 700;
  color: #00000059;
}
/* .error.errorEnd{
position: absolute;
left: 34%;
top:68px
}
.error.errorStart{
  position: absolute;
  left: 0%;
  top:68px
  } */
  .error.errorStart {
    position: absolute;
    top: 56px;
}
.error.errorEnd.text-end {
  position: absolute;
  right: 223px;
  top: 52px;
}