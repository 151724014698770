.membership_plans ul#plan-tabs {
    display: flex;
    margin: 0 auto;
    width: 200px;
    border-width: 0;
    background: #000;
    border-radius: 40px;
    padding: 5px;
}
.membership_plans ul#plan-tabs.nav-tabs .nav-link.active {
    color: #000;
}
.membership_plans ul#plan-tabs.nav-tabs .nav-link {
    width: 95px;
    border-radius: 40px;
    padding: 5px;
    font-weight: 600;
    color: #fff;
    border-width: 0;
}
.membership_plans .plan-box {
    box-shadow: 0px 0px 18px -6px rgb(0 0 0 / 10%);
    border-radius: 24px;
    padding: 40px 25px 25px;
    border: 1px solid #EAEAEA;
    position: relative;
    margin-top: 70px;
}
.membership_plans .plan-box.succes-plan-box {
    border: 2px solid #1E8400;
}
.membership_plans .plan-box.succes-plan-box span.plan-tag {
    background: #1E8400;
}
.membership_plans  span.plan-tag {
    background: #000;
    color: #fff;
    padding: 5px 10px;
    width: 130px;
    display: block;
    text-align: center;
    border-radius: 40px;
    font-weight: 600;
    font-size: 18px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -60px);
}
.membership_plans .plan-price {
    display: flex;
    justify-content: center;
    color: #000;
    align-items: baseline;
}
.membership_plans span.price-con {
    font-size: 40px;
    font-weight: 600;
}
.membership_plans ul.plan-point {
    min-height: 33vh;
    margin-top: 15px;
    list-style: none;
    padding: 0;
}
.membership_plans ul.plan-point li {
    padding-left: 35px;
    position: relative;
    font-size: 14px;
    margin-bottom: 10px;
}
.membership_plans ul.plan-point li:before {
    content: "";
    width: 22px;
    height: 22px;
    position: absolute;
    left: 0;
    top: 3px;
    background-size: 100% 100%;
}
.membership_plans .plan-box button.btn {
    margin: 0 auto;
    display: block;
    padding: 8px 20px;
}
.membership_plans .btn.gray-transparent-btn {
    border: 1px solid rgba(0, 0, 0, 0.4);
    color: rgba(0,0,0,0.6);
}
.membership_plans h2.main-title {
    font-size: 55px;
    font-weight: 600;
    padding-bottom: 15px;
}
