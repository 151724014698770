.radius-10{
	border-radius:10px;
}
.radius-20{
	border-radius:20px;
}
.radius-circle{
	border-radius:50%;
}
.margin-t40 {
	margin-top: 40px;
}
.margin-t30 {
	margin-top: 30px;
}
.mb30{
	margin-bottom: 30px;
}
.mb20{
	margin-bottom: 20px;
}
.mb10{
	margin-bottom: 10px;
}
.margin-t20 {
	margin-top: 20px;
}
.margin-t10 {
	margin-top: 10px;
}
.f14 {
    font-size: 14px;
}
.red-link{
	color:#FF0000;
}
.red-link:hover{
	color:#FF0000;
	opacity: 0.8;
}
.blue-link {
    color: #00acc0;
}
.blue-link:hover {
    color: #1f9ead;
}
.btn {
    box-shadow: unset!important;
}
button.btn.btn-transparent {
    border: 1px solid #000;
}
button.btn.btn-transparent:hover {
    background: #000;
    color: #fff;
}
.btn.btn-primary:hover {
    background: #db655a;
}
.btn.btn-primary {
    background: #E77469;
    border-color: #E77469;
}
.btn-dark:hover {
    background-color: #3c4248;
    border-color: #3c4248;
}
.btn.btn-default {
    background: #D9D9D9;
    border: 1px solid #D9D9D9;
}
.btn.btn-default:hover {
    background: #d3d2d2;
}
.btn.gray-transparent-btn:hover {
    background: rgba(0, 0, 0, 0.1);
    color: rgba(0,0,0,1);
}
.btn.gray-transparent-btn {
    border: 1px solid rgba(0, 0, 0, 0.4);
    color: rgba(0,0,0,0.6);
}
.btn-lg{
    padding: 13px 20px;	
}
.btn-md{
    padding: .375rem 20px;	
}
.full-btn {
    width: 100%;
}
.bg-gray {
	background: #D9CEB4;
}
.full-width-btn {
    width: 100%;
}
.download-btn {
    width: 55px;
    height: 55px;
    background: rgba(0, 172, 192, 0.59);
    border-radius: 12px;
    border-width: 0;
    color: #fff;
}
.download-btn:hover {
    background: rgba(0, 172, 192, 0.7);
}
.btn-gray{
    background: #f0f0f0;
}
.btn-gray:hover {
    background: #ededed;
}
.form-control {
	height: 60px;
    border-color: #ddd;
    border-radius: 0;
    outline: unset;
    box-shadow: unset;
    box-shadow: unset;
}
.form-control:focus {
    box-shadow: unset;
}
.btn.btn-success {
    background: #1E8400;
    border-color: #1E8400;
    border-radius: 10px;
}
.btn.btn-success:hover {
    background: #269107;
}
.table-action .btn.btn-success {
    background: #1E8400;
    border-color: #1E8400;
    width: 80px;
    border-radius: 4px;
    font-size: 12px;
}
.table-action .btn.btn-danger {
    background: #DA0000;
    border-color: #DA0000;
    width: 80px;
    border-radius: 4px;
    font-size: 12px;
}
.form-control {
    box-shadow: unset!important;
    outline: 0!important;
}

.sandwich-board button.btn.btn-dark {
    margin-bottom: 12px;
    height: 42px;
    width: 98px;
    margin-top: -4px;
}
.sandwich-board svg.svg-inline--fa.fa-floppy-disk {
    font-size: 25px;
    margin-bottom: 10px;
    /* margin-right: 2px; */
}

.dashboard-auto-wrapper.mb-4.sandwich-board-box {
    /* height: 71.5%; */
    height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
}

img.do-data {
    width: 100%;
    max-width: 198px;
    opacity: 0.5;
}

.sehdule-time label {
    font-size: 14px;
    font-weight: 500;
}

.opnening-time {
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-start;
    margin-top: -4rem;
}

input.time-contol {
    background: rgba(230, 230, 230, 0.6);
    border-radius: 0px;
    height: 49px;
    border: 1px solid #27ae60c7 ;
    outline: #27ae60c7 ;
    width: 91px;
    text-align: center;
    padding: 4px 7px;
}
.sehdule-time {
    background: #27ae60c7 !important;
}


.sehdule-close-time label {
    font-size: 14px;
    font-weight: 500;
}



.sehdule-close-time input.time-contol {
    background: rgba(230, 230, 230, 0.6);
    border-radius: 0px;
    height: 49px;
    border: 1px solid #80808091;
    outline: #80808091;
    width: 91px;
    text-align: center;
    padding: 4px 7px;
}
.sehdule-close-time {
    background:#80808091;
}

.switch input:checked + .slider {
    background-color: #65be81 !important;
}

.switch span.slider.round {
    width: 69px;
    height: 32px;
    margin-left: -8px;
    margin-top: -3px;
}

.switch-box .slider:before {
    position: absolute;
    content: "";
    height: 31px;
    width: 33px;
    left: 4px;
    top: 0px;
    background-color: white;
    transition: .4s;
}

.filter-body{
    overflow: auto;
    max-height: 500px;
}
.filter-title{
    width: 100%;
    display: flex;
    align-items: center;
}
.sandwichboard-lis img {
    width: 100%;
    max-width: 286px;
    margin-top: -6rem;
} 